import React from 'react'
import { mainPageProviderActions as ogCore } from 'og-core/providers/main-page'
import { mainPageProviderActions as astraCore } from 'astra-core/containers/MainPageProvider/slice'
import {
  EFeatureFlags,
  selectFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { CoreRootState } from 'astra-core/store'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AppStore } from 'app/lib/store'

import { Main, MainDeprecated } from './Main'

export const PageMain = () => {
  const isMainPageFromOGCoreEnabled = useFeatureFlag(
    EFeatureFlags.MAIN_PAGE_FROM_OG_CORE_ENABLED
  )
  return (
    <Layout type={ELayoutsList.MAIN}>
      {isMainPageFromOGCoreEnabled ? <Main /> : <MainDeprecated />}
    </Layout>
  )
}

export const pageMainPrefetch = ({ store }: { store: AppStore }) => {
  const state = store.getState() as unknown as CoreRootState
  const FF = selectFeatureFlags(state)
  const isMainPageFromOGCoreEnabled = FF.MAIN_PAGE_FROM_OG_CORE_ENABLED

  if (isMainPageFromOGCoreEnabled) {
    store.dispatch(ogCore.fetchTopLiveEvents())
    store.dispatch(ogCore.fetchTopLineEvents())
    store.dispatch(ogCore.fetchUpcomingEvents())
  } else {
    store.dispatch(astraCore.fetchTopLiveEvents())
    store.dispatch(astraCore.fetchTopLineEvents())
    store.dispatch(astraCore.fetchUpcomingEvents())
  }
}
